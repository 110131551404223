import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import axios from 'axios';
import { Col } from 'reactstrap';

const BASE_URL = "http://3.91.241.109";

const OrderService = () => {
    const [anomalies, setAnomalies] = useState([]);
    const [cities, setCities] = useState([]);
    const [anomalies_filtered, setAnomalies_filtered] = useState([]);
    const [formValues, setFormValues] = useState({
        anomalyCity: '',
        anomalyId: '',
        description: '',
        date: '',
    });
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    /*
    useEffect(() => {
        const fetchAnomalies = async () => {
            try {
                const response = await AuthService.getCiAnomalias()
                //.then((response) => {return response});
                ;
                setAnomalies(response.data.results);
            } catch (error) {
                console.log(error);
            }
        };
        fetchAnomalies();
    }, []);
    useEffect(()=>{
        const fetchCities = async () => {
            try {
                let cidades = [];
                const response = await AuthService.getCiAnomalias();
                response.data.results.forEach(item => {
                    if (!cidades.includes(item.cidade)&&item.cidade!=='') {
                        cidades.push(item.cidade);
                    };
                });
                setCities(cidades);
            } catch (error) {
                console.log(error);
            }
        };
        fetchCities();
    },[]);
    */
    const filterAnomalies = (anomalia, select_city) => {
        const filter_anomalias = [];
        /*
        anomalia.forEach(item => {
            if (select_city === item.cidade) {
                filter_anomalias.push(item);
            }
        });
        setAnomalies_filtered(filter_anomalias);
    */
    };

    const handleInputChangeCity = (event) => {
        const { name, value } = event.target;
        filterAnomalies(anomalies, value);
        setFormValues({ ...formValues, [name]: value });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/orderm-servico/`, {
                anomalia_id: formValues.anomalyId,
                descricao: formValues.description,
                data: formValues.date,
            });
            setAlertMessage('Ordem de serviço criada com sucesso.');
            setAlertVisible(true);
        } catch (error) {
            setAlertMessage('Erro ao criar ordem de serviço. Tente novamente mais tarde.');
            setAlertVisible(true);
            console.log(error);
        }
        setFormValues({
            anomalyCity: '',
            anomalyId: '',
            description: '',
            date: '',
        });
    };

    return (
        <>
            <div className="content">

                <h1 class="card-title color_title">Ordem de Serviço</h1>
                <Form onSubmit={handleFormSubmit}>
                    <FormGroup row>

                        {/*Filtrar por cidade*/}
                        <Label for="city" sm={2}>Cidade:</Label>
                        <Col sm={3}>
                            <Input type="select" name="anomalyCity" id="anomalyCity" value={formValues.anomalyCity} onChange={handleInputChangeCity}>
                                <option value="">Selecione uma Cidade</option>
                                {cities.map((city) => (
                                    <option key={city} value={city}>
                                        {city}
                                    </option>
                                ))}
                            </Input>
                        </Col>

                        {/*Filtrar por bairro*/}
                        <Label class="color_title" for="anomalyId" sm={2}>Anomalia</Label>
                        <Col sm={3}>
                            <Input type="select" name="anomalyId" id="anomalyId" value={formValues.anomalyId} onChange={handleInputChange}>
                                <option value="">Selecione uma anomalia</option>
                                {anomalies_filtered.map((anomaly) => (
                                    <option key={anomaly.id} value={anomaly.id}>
                                        {anomaly.id} - codigo {anomaly.id_ci} - {anomaly.infiltra}
                                    </option>
                                ))}
                            </Input>
                        </Col>

                    </FormGroup>




                    <FormGroup row>
                        <Label class="color_title" for="description" sm={2}>Descrição</Label>
                        <Col sm={10}>
                            <Input type="text" name="description" id="description" value={formValues.description} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label class="color_title" for="date" sm={2}>Data</Label>
                        <Col sm={10}>
                            <Input type="date" name="date" id="date" value={formValues.date} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <Button type="submit" color="primary">Criar</Button>
                </Form>
                {alertVisible && <Alert color="info">{alertMessage}</Alert>}
            </div>

        </>
    );
};

export default OrderService;