import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// CI_ANOMALIAS Redux States
import {
    GET_CI_ANOMALIAS,
    ADD_NEW_CI_ANOMALIAS,
    DELETE_CI_ANOMALIAS,
    UPDATE_CI_ANOMALIAS,
} from "./actionType";
import {
    CiAnomaliasApiResponseSuccess, CiAnomaliasApiResponseError,
    addCiAnomaliasSuccess,
    addCiAnomaliasFail,
    updateCiAnomaliasSuccess,
    updateCiAnomaliasFail,
    deleteCiAnomaliasSuccess,
    deleteCiAnomaliasFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getCiAnomalias as getCiAnomaliasApi,
    addNewCiAnomalias,
    updateCiAnomalias,
    deleteCiAnomalias,
} from "../../helpers/fakebackend_helper";

function* getCiAnomalias() {
    try {
        const response = yield call(getCiAnomaliasApi);
        //console.log(response);
        yield put(CiAnomaliasApiResponseSuccess(GET_CI_ANOMALIAS, response.results));
    } catch (error) {
        //console.log(error);
        yield put(CiAnomaliasApiResponseError(GET_CI_ANOMALIAS, error));
    }
}

function* onAddNewCiAnomalias({ payload: cianomalias }) {
    try {
        const response = yield call(addNewCiAnomalias, cianomalias);
        yield put(addCiAnomaliasSuccess(response));
        toast.success("CiAnomalias Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addCiAnomaliasFail(error));
        toast.error("CiAnomalias Added Failed", { autoClose: 3000 });
    }
}

function* onDeleteCiAnomalias({ payload: cianomalias }) {
    try {
        const response = yield call(deleteCiAnomalias, cianomalias);
        yield put(deleteCiAnomaliasSuccess({ cianomalias, ...response }));
        toast.success("CiAnomalias Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteCiAnomaliasFail(error));
        toast.error("CiAnomalias Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdateCiAnomalias({ payload: cianomalias }) {
    try {
        const response = yield call(updateCiAnomalias, cianomalias);
        yield put(updateCiAnomaliasSuccess(response));
        toast.success("CiAnomalias Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateCiAnomaliasFail(error));
        toast.error("CiAnomalias Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetCiAnomalias() {
    yield takeEvery(GET_CI_ANOMALIAS, getCiAnomalias);
}

export function* watchAddNewCiAnomalias() {
    yield takeEvery(ADD_NEW_CI_ANOMALIAS, onAddNewCiAnomalias);
}

export function* watchUpdateCiAnomalias() {
    yield takeEvery(UPDATE_CI_ANOMALIAS, onUpdateCiAnomalias);
}

export function* watchDeleteCiAnomalias() {
    yield takeEvery(DELETE_CI_ANOMALIAS, onDeleteCiAnomalias);
}

function* cianomaliasSaga() {
    yield all([
        fork(watchGetCiAnomalias),
        fork(watchAddNewCiAnomalias),
        fork(watchUpdateCiAnomalias),
        fork(watchDeleteCiAnomalias)
    ]
    );
}

export default cianomaliasSaga;
