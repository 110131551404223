import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

//Import maps
import OrderService from "./OrderServices/OrderService";

const Orders = () => {
    document.title = "Ordem de Serviços | SANapp";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Ordem de Serviços" pageTitle="Search" />

                    <Row >
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="order-services" className="order-services">
                                        <OrderService />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Orders;