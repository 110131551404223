import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// TRECHO_CADASTROS Redux States
import {
    GET_TRECHO_CADASTROS,
    ADD_NEW_TRECHO_CADASTROS,
    DELETE_TRECHO_CADASTROS,
    UPDATE_TRECHO_CADASTROS,
} from "./actionType";
import {
    TrechoCadastrosApiResponseSuccess, TrechoCadastrosApiResponseError,
    addTrechoCadastrosSuccess,
    addTrechoCadastrosFail,
    updateTrechoCadastrosSuccess,
    updateTrechoCadastrosFail,
    deleteTrechoCadastrosSuccess,
    deleteTrechoCadastrosFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getTrechoCadastros as getTrechoCadastrosApi,
    addNewTrechoCadastros,
    updateTrechoCadastros,
    deleteTrechoCadastros,
} from "../../helpers/fakebackend_helper";

function* getTrechoCadastros() {
    try {
        const response = yield call(getTrechoCadastrosApi);
        //console.log(response);
        yield put(TrechoCadastrosApiResponseSuccess(GET_TRECHO_CADASTROS, response.results));
    } catch (error) {
        //console.log(error);
        yield put(TrechoCadastrosApiResponseError(GET_TRECHO_CADASTROS, error));
    }
}

function* onAddNewTrechoCadastros({ payload: trechocadastros }) {
    try {
        const response = yield call(addNewTrechoCadastros, trechocadastros);
        yield put(addTrechoCadastrosSuccess(response));
        toast.success("TrechoCadastros Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addTrechoCadastrosFail(error));
        toast.error("TrechoCadastros Added Failed", { autoClose: 3000 });
    }
}

function* onDeleteTrechoCadastros({ payload: trechocadastros }) {
    try {
        const response = yield call(deleteTrechoCadastros, trechocadastros);
        yield put(deleteTrechoCadastrosSuccess({ trechocadastros, ...response }));
        toast.success("TrechoCadastros Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteTrechoCadastrosFail(error));
        toast.error("TrechoCadastros Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdateTrechoCadastros({ payload: trechocadastros }) {
    try {
        const response = yield call(updateTrechoCadastros, trechocadastros);
        yield put(updateTrechoCadastrosSuccess(response));
        toast.success("TrechoCadastros Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateTrechoCadastrosFail(error));
        toast.error("TrechoCadastros Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetTrechoCadastros() {
    yield takeEvery(GET_TRECHO_CADASTROS, getTrechoCadastros);
}

export function* watchAddNewTrechoCadastros() {
    yield takeEvery(ADD_NEW_TRECHO_CADASTROS, onAddNewTrechoCadastros);
}

export function* watchUpdateTrechoCadastros() {
    yield takeEvery(UPDATE_TRECHO_CADASTROS, onUpdateTrechoCadastros);
}

export function* watchDeleteTrechoCadastros() {
    yield takeEvery(DELETE_TRECHO_CADASTROS, onDeleteTrechoCadastros);
}

function* trechocadastrosSaga() {
    yield all([
        fork(watchGetTrechoCadastros),
        fork(watchAddNewTrechoCadastros),
        fork(watchUpdateTrechoCadastros),
        fork(watchDeleteTrechoCadastros)
    ]
    );
}

export default trechocadastrosSaga;
