import {
    GET_PV_ANOMALIAS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_PV_ANOMALIAS_SUCCESS,
    ADD_PV_ANOMALIAS_FAIL,
    UPDATE_PV_ANOMALIAS_SUCCESS,
    UPDATE_PV_ANOMALIAS_FAIL,
    DELETE_PV_ANOMALIAS_SUCCESS,
    DELETE_PV_ANOMALIAS_FAIL,
} from "./actionType";

const INIT_STATE = {
    pvAnomalias: [],
};

const PvAnomalias = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_PV_ANOMALIAS:
                    return {
                        ...state,
                        pvAnomalias: action.payload.data,
                        isPvAnomaliasCreated: false,
                        isPvAnomaliasSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_PV_ANOMALIAS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isPvAnomaliasCreated: false,
                        isPvAnomaliasSuccess: true
                    };

                default:
                    return { ...state };
            }

        case GET_PV_ANOMALIAS: {
            return {
                ...state,
                isPvAnomaliasCreated: false
            };
        }

        case ADD_PV_ANOMALIAS_SUCCESS:
            return {
                ...state,
                isPvAnomaliasCreated: true,
                pvAnomalias: [...state.pvAnomalias, action.payload.data],
                isPvAnomaliasAdd: true,
                isPvAnomaliasAddFail: false,
            };

        case ADD_PV_ANOMALIAS_FAIL:
            return {
                ...state,
                error: action.payload,
                isPvAnomaliasAdd: false,
                isPvAnomaliasAddFail: true,
            };

        case UPDATE_PV_ANOMALIAS_SUCCESS:
            return {
                ...state,
                pvAnomalias: state.pvAnomalias.map(pvanomalias =>
                    pvanomalias._id.toString() === action.payload.data._id.toString()
                        ? { ...pvanomalias, ...action.payload.data }
                        : pvanomalias
                ),
                isPvAnomaliasUpdate: true,
                isPvAnomaliasUpdateFail: false
            };

        case UPDATE_PV_ANOMALIAS_FAIL:
            return {
                ...state,
                error: action.payload,
                isPvAnomaliasUpdate: false,
                isPvAnomaliasUpdateFail: true
            };

        case DELETE_PV_ANOMALIAS_SUCCESS:
            return {
                ...state,
                pvAnomalias: state.pvAnomalias.filter(
                    pvanomalias => pvanomalias._id.toString() !== action.payload.pvanomalias.toString()
                ),
                isPvAnomaliasDelete: true,
                isPvAnomaliasDeleteFail: false
            };

        case DELETE_PV_ANOMALIAS_FAIL:
            return {
                ...state,
                error: action.payload,
                isPvAnomaliasDelete: false,
                isPvAnomaliasDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default PvAnomalias;