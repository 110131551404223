// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PV_CADASTROS = "GET_PV_CADASTROS";

/**
* Add PV_CADASTROS
*/
export const ADD_NEW_PV_CADASTROS = "ADD_NEW_PV_CADASTROS";
export const ADD_PV_CADASTROS_SUCCESS = "ADD_PV_CADASTROS_SUCCESS";
export const ADD_PV_CADASTROS_FAIL = "ADD_PV_CADASTROS_FAIL";

/**
 * Edit PV_CADASTROS
 */
export const UPDATE_PV_CADASTROS = "UPDATE_PV_CADASTROS";
export const UPDATE_PV_CADASTROS_SUCCESS = "UPDATE_PV_CADASTROS_SUCCESS";
export const UPDATE_PV_CADASTROS_FAIL = "UPDATE_PV_CADASTROS_FAIL";

/**
 * Delete PV_CADASTROS
 */
export const DELETE_PV_CADASTROS = "DELETE_PV_CADASTROS";
export const DELETE_PV_CADASTROS_SUCCESS = "DELETE_PV_CADASTROS_SUCCESS";
export const DELETE_PV_CADASTROS_FAIL = "DELETE_PV_CADASTROS_FAIL";