import {
    GET_CI_ANOMALIAS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_CI_ANOMALIAS_SUCCESS,
    ADD_CI_ANOMALIAS_FAIL,
    UPDATE_CI_ANOMALIAS_SUCCESS,
    UPDATE_CI_ANOMALIAS_FAIL,
    DELETE_CI_ANOMALIAS_SUCCESS,
    DELETE_CI_ANOMALIAS_FAIL,
} from "./actionType";

const INIT_STATE = {
    ciAnomalias: [],
};

const CiAnomalias = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_CI_ANOMALIAS:
                    return {
                        ...state,
                        ciAnomalias: action.payload.data,
                        isCiAnomaliasCreated: false,
                        isCiAnomaliasSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_CI_ANOMALIAS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isCiAnomaliasCreated: false,
                        isCiAnomaliasSuccess: true
                    };

                default:
                    return { ...state };
            }

        case GET_CI_ANOMALIAS: {
            return {
                ...state,
                isCiAnomaliasCreated: false
            };
        }

        case ADD_CI_ANOMALIAS_SUCCESS:
            return {
                ...state,
                isCiAnomaliasCreated: true,
                ciAnomalias: [...state.ciAnomalias, action.payload.data],
                isCiAnomaliasAdd: true,
                isCiAnomaliasAddFail: false,
            };

        case ADD_CI_ANOMALIAS_FAIL:
            return {
                ...state,
                error: action.payload,
                isCiAnomaliasAdd: false,
                isCiAnomaliasAddFail: true,
            };

        case UPDATE_CI_ANOMALIAS_SUCCESS:
            return {
                ...state,
                ciAnomalias: state.ciAnomalias.map(cianomalias =>
                    cianomalias._id.toString() === action.payload.data._id.toString()
                        ? { ...cianomalias, ...action.payload.data }
                        : cianomalias
                ),
                isCiAnomaliasUpdate: true,
                isCiAnomaliasUpdateFail: false
            };

        case UPDATE_CI_ANOMALIAS_FAIL:
            return {
                ...state,
                error: action.payload,
                isCiAnomaliasUpdate: false,
                isCiAnomaliasUpdateFail: true
            };

        case DELETE_CI_ANOMALIAS_SUCCESS:
            return {
                ...state,
                ciAnomalias: state.ciAnomalias.filter(
                    cianomalias => cianomalias._id.toString() !== action.payload.cianomalias.toString()
                ),
                isCiAnomaliasDelete: true,
                isCiAnomaliasDeleteFail: false
            };

        case DELETE_CI_ANOMALIAS_FAIL:
            return {
                ...state,
                error: action.payload,
                isCiAnomaliasDelete: false,
                isCiAnomaliasDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default CiAnomalias;