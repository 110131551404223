import {
  GET_PV_CADASTROS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_PV_CADASTROS,
  ADD_PV_CADASTROS_SUCCESS,
  ADD_PV_CADASTROS_FAIL,
  UPDATE_PV_CADASTROS,
  UPDATE_PV_CADASTROS_SUCCESS,
  UPDATE_PV_CADASTROS_FAIL,
  DELETE_PV_CADASTROS,
  DELETE_PV_CADASTROS_SUCCESS,
  DELETE_PV_CADASTROS_FAIL,
} from "./actionType";

// common success
export const PvCadastrosApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const PvCadastrosApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPvCadastros = () => ({
  type: GET_PV_CADASTROS,
});

export const updatePvCadastros = pvcadastros => ({
  type: UPDATE_PV_CADASTROS,
  payload: pvcadastros,
});

export const updatePvCadastrosSuccess = pvcadastros => ({
  type: UPDATE_PV_CADASTROS_SUCCESS,
  payload: pvcadastros,
});

export const updatePvCadastrosFail = error => ({
  type: UPDATE_PV_CADASTROS_FAIL,
  payload: error,
});

export const addNewPvCadastros = pvcadastros => ({
  type: ADD_NEW_PV_CADASTROS,
  payload: pvcadastros,
});

export const addPvCadastrosSuccess = pvcadastros => ({
  type: ADD_PV_CADASTROS_SUCCESS,
  payload: pvcadastros,
});

export const addPvCadastrosFail = error => ({
  type: ADD_PV_CADASTROS_FAIL,
  payload: error,
});
export const deletePvCadastros = pvcadastros => ({
  type: DELETE_PV_CADASTROS,
  payload: pvcadastros,
});

export const deletePvCadastrosSuccess = pvcadastros => ({
  type: DELETE_PV_CADASTROS_SUCCESS,
  payload: pvcadastros,
});

export const deletePvCadastrosFail = error => ({
  type: DELETE_PV_CADASTROS_FAIL,
  payload: error,
});