import {
  GET_TRECHO_CADASTROS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_TRECHO_CADASTROS,
  ADD_TRECHO_CADASTROS_SUCCESS,
  ADD_TRECHO_CADASTROS_FAIL,
  UPDATE_TRECHO_CADASTROS,
  UPDATE_TRECHO_CADASTROS_SUCCESS,
  UPDATE_TRECHO_CADASTROS_FAIL,
  DELETE_TRECHO_CADASTROS,
  DELETE_TRECHO_CADASTROS_SUCCESS,
  DELETE_TRECHO_CADASTROS_FAIL,
} from "./actionType";

// common success
export const TrechoCadastrosApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const TrechoCadastrosApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getTrechoCadastros = () => ({
  type: GET_TRECHO_CADASTROS,
});

export const updateTrechoCadastros = trechocadastros => ({
  type: UPDATE_TRECHO_CADASTROS,
  payload: trechocadastros,
});

export const updateTrechoCadastrosSuccess = trechocadastros => ({
  type: UPDATE_TRECHO_CADASTROS_SUCCESS,
  payload: trechocadastros,
});

export const updateTrechoCadastrosFail = error => ({
  type: UPDATE_TRECHO_CADASTROS_FAIL,
  payload: error,
});

export const addNewTrechoCadastros = trechocadastros => ({
  type: ADD_NEW_TRECHO_CADASTROS,
  payload: trechocadastros,
});

export const addTrechoCadastrosSuccess = trechocadastros => ({
  type: ADD_TRECHO_CADASTROS_SUCCESS,
  payload: trechocadastros,
});

export const addTrechoCadastrosFail = error => ({
  type: ADD_TRECHO_CADASTROS_FAIL,
  payload: error,
});
export const deleteTrechoCadastros = trechocadastros => ({
  type: DELETE_TRECHO_CADASTROS,
  payload: trechocadastros,
});

export const deleteTrechoCadastrosSuccess = trechocadastros => ({
  type: DELETE_TRECHO_CADASTROS_SUCCESS,
  payload: trechocadastros,
});

export const deleteTrechoCadastrosFail = error => ({
  type: DELETE_TRECHO_CADASTROS_FAIL,
  payload: error,
});