import React, { useState, useEffect } from 'react';
import {
    MapContainer, TileLayer, Popup,
    LayersControl, LayerGroup, CircleMarker,
    Circle, Rectangle, Marker, Polyline,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// reactstrap components
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import L from 'leaflet';

import proj4 from 'proj4';
// Api Conection
import { useSelector, useDispatch } from "react-redux";
import { getCiCadastros } from "../../../../store/cicadastros/action";
import { getCiAnomalias } from "../../../../store/cianomalias/action";
import { getPvCadastros } from "../../../../store/pvcadastros/action";
import { getPvAnomalias } from "../../../../store/pvanomalias/action";
import { getTrechoCadastros } from "../../../../store/trechocadastros/action";
import { getTrechoAnomalias } from "../../../../store/trechoanomalias/action";

import bueiro from "../../../../assets/img/caixa_inspecao.png";
//import bueiro from "../../../../assets/img/bueiro.png";

const { BaseLayer, Overlay } = LayersControl;

function Esgoto() {

    const [position] = useState([-27.612690, -48.596382]); // posição inicial no mapa

    // MAP COLORS
    const BlueOptions = { fillColor: 'blue' };
    const RedOptions = { color: 'red', fillColor: 'red' };
    const greenOptions = { color: 'green', fillColor: 'green' };
    const purpleOptions = { color: 'purple' };
    const blackOptions = { color: 'black' }
    const limeOptions = { color: 'lime' }

    // CARREGA DADOS API COM REDUX
    const dispatch = useDispatch();

    // GET CI CADASTROS
    const { ciCadastros, isCiCadastrosCreated, isCiCadastrosSuccess, errorCiCadastros } = useSelector((state) => ({
        ciCadastros: state.CiCadastros.ciCadastros,
        isCiCadastrosCreated: state.CiCadastros.isCiCadastrosCreated,
        isCiCadastrosSuccess: state.CiCadastros.isCiCadastrosSuccess,
        error: state.CiCadastros.error,
    }));
    useEffect(() => {
        if (ciCadastros && !ciCadastros.length) {
            dispatch(getCiCadastros());
        }
    }, [dispatch, ciCadastros]);
    //console.log(ciCadastros);

    //GET CI ANOMALIAS
    const { ciAnomalias, isCiAnomaliasCreated, isCiAnomaliasSuccess, errorCiAnomalias } = useSelector((state) => ({
        ciAnomalias: state.CiAnomalias.ciAnomalias,
        isCiAnomaliasCreated: state.CiAnomalias.isCiAnomaliasCreated,
        isCiAnomaliasSuccess: state.CiAnomalias.isCiAnomaliasSuccess,
        errorCiAnomalias: state.CiAnomalias.error,
    }));
    useEffect(() => {
        if (ciAnomalias && !ciAnomalias.length) {
            dispatch(getCiAnomalias());
        }
    }, [dispatch, ciAnomalias]);
    //console.log(ciAnomalias);

    // GET PV CADASTROS
    const { pvCadastros, isPvCadastrosCreated, isPvCadastrosSuccess, errorPvCadastros } = useSelector((state) => ({
        pvCadastros: state.PvCadastros.pvCadastros,
        isPvCadastrosCreated: state.PvCadastros.isPvCadastrosCreated,
        isPvCadastrosSuccess: state.PvCadastros.isPvCadastrosSuccess,
        error: state.PvCadastros.error,
    }));
    useEffect(() => {
        if (pvCadastros && !pvCadastros.length) {
            dispatch(getPvCadastros());
        }
    }, [dispatch, pvCadastros]);
    //console.log(pvCadastros);

    //GET PV ANOMALIAS
    const { pvAnomalias, isPvAnomaliasCreated, isPvAnomaliasSuccess, errorPvAnomalias } = useSelector((state) => ({
        pvAnomalias: state.PvAnomalias.pvAnomalias,
        isPvAnomaliasCreated: state.PvAnomalias.isPvAnomaliasCreated,
        isPvAnomaliasSuccess: state.PvAnomalias.isPvAnomaliasSuccess,
        errorPvAnomalias: state.PvAnomalias.error,
    }));
    useEffect(() => {
        if (pvAnomalias && !pvAnomalias.length) {
            dispatch(getPvAnomalias());
        }
    }, [dispatch, pvAnomalias]);
    //console.log(pvAnomalias)
    
    // GET TRECHO CADASTROS
    const { trechoCadastros, isTrechoCadastrosCreated, isTrechoCadastrosSuccess, errorTrechoCadastros } = useSelector((state) => ({
        trechoCadastros: state.TrechoCadastros.trechoCadastros,
        isTrechoCadastrosCreated: state.TrechoCadastros.isTrechoCadastrosCreated,
        isTrechoCadastrosSuccess: state.TrechoCadastros.isTrechoCadastrosSuccess,
        error: state.TrechoCadastros.error,
    }));
    useEffect(() => {
        if (trechoCadastros && !trechoCadastros.length) {
            dispatch(getTrechoCadastros());
        }
    }, [dispatch, trechoCadastros]);
    //console.log(trechoCadastros);

    //GET TRECHO ANOMALIAS
    const { trechoAnomalias, isTrechoAnomaliasCreated, isTrechoAnomaliasSuccess, errorTrechoAnomalias } = useSelector((state) => ({
        trechoAnomalias: state.TrechoAnomalias.trechoAnomalias,
        isTrechoAnomaliasCreated: state.TrechoAnomalias.isTrechoAnomaliasCreated,
        isTrechoAnomaliasSuccess: state.TrechoAnomalias.isTrechoAnomaliasSuccess,
        errorTrechoAnomalias: state.TrechoAnomalias.error,
    }));
    useEffect(() => {
        if (trechoAnomalias && !trechoAnomalias.length) {
            dispatch(getTrechoAnomalias());
        }
    }, [dispatch, trechoAnomalias]);
    //console.log(trechoAnomalias)

    // CONVERTE AS COORDENADAS
    const transformCoord = (coord) => {
        proj4.defs("EPSG:31982", "+proj=utm +zone=22 +south +ellps=GRS80 +units=m +no_defs");
        const coordArray = coord.split('(')[1].split(')')[0].split(' ');
        const x = parseFloat(coordArray[0]);
        const y = parseFloat(coordArray[1]);
        const transformed = proj4("EPSG:31982", "EPSG:4326", [x, y]);
        return [transformed[1], transformed[0]]
    };
    const transformline = (line) => {
        proj4.defs("EPSG:31982", "+proj=utm +zone=22 +south +ellps=GRS80 +units=m +no_defs");
        const lineArray = line.replace(',','').replace('((','(').replace('))',')').split('(')[1].split(')')[0].split(' ');
        const w = parseFloat(lineArray[0]);
        const x = parseFloat(lineArray[1]);
        const y = parseFloat(lineArray[2]);
        const z = parseFloat(lineArray[3]);
        const transformed1 = proj4("EPSG:31982", "EPSG:4326", [w, x]);
        const transformed2 = proj4("EPSG:31982", "EPSG:4326", [y, z]);
        return [[transformed1[1], transformed1[0]], [transformed2[1], transformed2[0]]]
    };

    // Define o icone para CIs
    const bueiroMB = L.icon({
        iconUrl: bueiro,
        iconSize: [15, 15],
        iconAnchor: [8, 8],
        popupAnchor: [0, 0],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });

    return (
        <>
            <div className="content">
                <Row className='row'>
                    <Col md="12">
                        <Card className="card-plain">
                            <CardBody>
                                <div
                                    id="map"
                                    className="map"
                                    style={{ position: "relative", overflow: "hidden" }}
                                >

                                    <MapContainer center={position} zoom={16.5} style={{ height: '500px' }} maxZoom={22} 
                                    keyboard={false}
                                    scrollWheelZoom={true}
                                    >
                                        <LayersControl position="topright">
                                            <BaseLayer checked name="Webmap Padrão OpenStreetMap">
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    attribution="Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors"
                                                    maxZoom={22}
                                                    opacity={1.0}
                                                    foo='bar'
                                                />
                                            </BaseLayer>

                                            <BaseLayer name="Webmap Preto e Branco cartocdn">
                                                <TileLayer
                                                    url='https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png'
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                                    subdomains='abcd'
                                                    maxZoom={22}
                                                    foo='bar'
                                                />
                                            </BaseLayer>
                                            
                                            <BaseLayer name="Webmap Google Maps Satelite">
                                                <TileLayer
                                                    url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                                    subdomains={['mt0','mt1','mt2','mt3']}
                                                    maxZoom={22}
                                                />
                                            </BaseLayer>

                                            <BaseLayer name="Webmap Google Maps Terrain">
                                                <TileLayer
                                                    url="https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}"
                                                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                                    subdomains={['mt0','mt1','mt2','mt3']}
                                                    maxZoom={22}
                                                />
                                            </BaseLayer>

                                            <BaseLayer name="Webmap Google Maps Hybrid">
                                                <TileLayer
                                                    url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                                                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                                    subdomains={['mt0','mt1','mt2','mt3']}
                                                    maxZoom={22}
                                                />
                                            </BaseLayer>

                                            <BaseLayer name="Webmap Google Maps Street">
                                                <TileLayer
                                                    url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                                                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                                    subdomains={['mt0','mt1','mt2','mt3']}
                                                    maxZoom={22}
                                                />
                                            </BaseLayer>

                                            <Overlay checked name="CADASTROS CAIXAS DE INSPEÇÃO">
                                                <LayerGroup>

                                                    {ciCadastros.map((item) => (
                                                        <Circle center={transformCoord(item.geom)} pathOptions={BlueOptions} radius={1}>
                                                            
                                                            <Popup>
                                                                id CI: {item.id_ci}
                                                                <br />
                                                                Cidade: {item.cidade}
                                                                <br />
                                                                Bairro: {item.bairro}
                                                                <br />
                                                                Tipo do CI: {item.tipo_ci}
                                                                <br />
                                                                Formato poço: {item.form_poco}
                                                                <br />
                                                                material do poço: {item.mat_poco}
                                                                <br />
                                                                material da tampa: {item.mat_tampa}
                                                                <br />
                                                                material do tubo: {item.mat_tubo_s}
                                                                <br />
                                                                <br />
                                                                <Button>Abrir OS</Button>
                                                            </Popup>
                                                        </Circle>
                                                    ))}

                                                </LayerGroup>
                                            </Overlay>

                                            <Overlay checked name="ANOMALIAS CAIXAS DE INSPEÇÃO">
                                                <LayerGroup>

                                                    {ciAnomalias.map((item) => (
                                                        <Circle center={transformCoord(item.geom)} pathOptions={RedOptions} radius={1}>

                                                            <Popup>
                                                                Caracteristicas do Acabamento: {item.carac_acab}
                                                                <br />
                                                                Id da caixa de inspeção: {item.id_ci}
                                                                <br />
                                                                Cidade: {item.cidade}
                                                                <br />
                                                                Bairro: {item.bairro}
                                                                <br />
                                                                <br />
                                                                <Button>Abrir OS</Button>
                                                            </Popup>

                                                        </Circle>
                                                    ))}

                                                </LayerGroup>
                                            </Overlay>

                                            <Overlay checked name="ANOMALIAS POÇOS VISITA">
                                                <LayerGroup>

                                                    {pvAnomalias.map((item) => (
                                                        <Circle center={transformCoord(item.geom)} pathOptions={greenOptions} radius={1}>

                                                            <Popup>
                                                                Caracteristicas do Acabamento: {item.carac_acab}
                                                                <br />
                                                                Id da caixa de inspeção: {item.id_ci}
                                                                <br />
                                                                Cidade: {item.cidade}
                                                                <br />
                                                                Bairro: {item.bairro}
                                                                <br />
                                                                <br />
                                                                <Button>Abrir OS</Button>
                                                            </Popup>

                                                        </Circle>
                                                    ))}

                                                </LayerGroup>
                                            </Overlay>

                                            <Overlay checked name="CADASTROS POÇOS VISITA">
                                                <LayerGroup>

                                                    {pvCadastros.map((item) => (
                                                        <Circle center={transformCoord(item.geom)} pathOptions={purpleOptions} radius={1}>

                                                            <Popup>
                                                                Caracteristicas do Acabamento: {item.carac_acab}
                                                                <br />
                                                                Id da caixa de inspeção: {item.id_ci}
                                                                <br />
                                                                Cidade: {item.cidade}
                                                                <br />
                                                                Bairro: {item.bairro}
                                                                <br />
                                                                <br />
                                                                <Button>Abrir OS</Button>
                                                            </Popup>

                                                        </Circle>
                                                    ))}

                                                </LayerGroup>
                                            </Overlay>

                                            <Overlay checked name="ANOMALIAS DE TRECHOS">
                                                <LayerGroup>

                                                    {trechoAnomalias.map((item) => (
                                                        <Circle center={transformCoord(item.geom)} pathOptions={purpleOptions} radius={1}>

                                                            <Popup>
                                                                Caracteristicas do Acabamento: {item.carac_acab}
                                                                <br />
                                                                Id da caixa de inspeção: {item.id_ci}
                                                                <br />
                                                                Cidade: {item.cidade}
                                                                <br />
                                                                Bairro: {item.bairro}
                                                                <br />
                                                                <br />
                                                                <Button>Abrir OS</Button>
                                                            </Popup>

                                                        </Circle>
                                                    ))}

                                                </LayerGroup>
                                            </Overlay>
                                            
                                            <Overlay checked name="CADASTROS DE TRECHOS">
                                                <LayerGroup>

                                                    {trechoCadastros.map((item) => (
                                                        <Polyline pathOptions={limeOptions} positions={transformline(item.geom)} />
                                                    ))}

                                                </LayerGroup>
                                            </Overlay>

                                        </LayersControl>

                                    </MapContainer>
                                </div>

                                <div className="info-window-content"><h2>Mapas com referencias</h2>
                                    <p>Veja aqui todas as informações de uma determinada região</p>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        </>
    );
}

export default Esgoto;