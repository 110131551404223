import {
    GET_TRECHO_ANOMALIAS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_TRECHO_ANOMALIAS_SUCCESS,
    ADD_TRECHO_ANOMALIAS_FAIL,
    UPDATE_TRECHO_ANOMALIAS_SUCCESS,
    UPDATE_TRECHO_ANOMALIAS_FAIL,
    DELETE_TRECHO_ANOMALIAS_SUCCESS,
    DELETE_TRECHO_ANOMALIAS_FAIL,
} from "./actionType";

const INIT_STATE = {
    trechoAnomalias: [],
};

const TrechoAnomalias = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_TRECHO_ANOMALIAS:
                    return {
                        ...state,
                        trechoAnomalias: action.payload.data,
                        isTrechoAnomaliasCreated: false,
                        isTrechoAnomaliasSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_TRECHO_ANOMALIAS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isTrechoAnomaliasCreated: false,
                        isTrechoAnomaliasSuccess: true
                    };

                default:
                    return { ...state };
            }

        case GET_TRECHO_ANOMALIAS: {
            return {
                ...state,
                isTrechoAnomaliasCreated: false
            };
        }

        case ADD_TRECHO_ANOMALIAS_SUCCESS:
            return {
                ...state,
                isTrechoAnomaliasCreated: true,
                trechoAnomalias: [...state.trechoAnomalias, action.payload.data],
                isTrechoAnomaliasAdd: true,
                isTrechoAnomaliasAddFail: false,
            };

        case ADD_TRECHO_ANOMALIAS_FAIL:
            return {
                ...state,
                error: action.payload,
                isTrechoAnomaliasAdd: false,
                isTrechoAnomaliasAddFail: true,
            };

        case UPDATE_TRECHO_ANOMALIAS_SUCCESS:
            return {
                ...state,
                trechoAnomalias: state.trechoAnomalias.map(trechoanomalias =>
                    trechoanomalias._id.toString() === action.payload.data._id.toString()
                        ? { ...trechoanomalias, ...action.payload.data }
                        : trechoanomalias
                ),
                isTrechoAnomaliasUpdate: true,
                isTrechoAnomaliasUpdateFail: false
            };

        case UPDATE_TRECHO_ANOMALIAS_FAIL:
            return {
                ...state,
                error: action.payload,
                isTrechoAnomaliasUpdate: false,
                isTrechoAnomaliasUpdateFail: true
            };

        case DELETE_TRECHO_ANOMALIAS_SUCCESS:
            return {
                ...state,
                trechoAnomalias: state.trechoAnomalias.filter(
                    trechoanomalias => trechoanomalias._id.toString() !== action.payload.trechoanomalias.toString()
                ),
                isTrechoAnomaliasDelete: true,
                isTrechoAnomaliasDeleteFail: false
            };

        case DELETE_TRECHO_ANOMALIAS_FAIL:
            return {
                ...state,
                error: action.payload,
                isTrechoAnomaliasDelete: false,
                isTrechoAnomaliasDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default TrechoAnomalias;