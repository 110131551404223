import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// PV_ANOMALIAS Redux States
import {
    GET_PV_ANOMALIAS,
    ADD_NEW_PV_ANOMALIAS,
    DELETE_PV_ANOMALIAS,
    UPDATE_PV_ANOMALIAS,
} from "./actionType";
import {
    PvAnomaliasApiResponseSuccess, PvAnomaliasApiResponseError,
    addPvAnomaliasSuccess,
    addPvAnomaliasFail,
    updatePvAnomaliasSuccess,
    updatePvAnomaliasFail,
    deletePvAnomaliasSuccess,
    deletePvAnomaliasFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getPvAnomalias as getPvAnomaliasApi,
    addNewPvAnomalias,
    updatePvAnomalias,
    deletePvAnomalias,
} from "../../helpers/fakebackend_helper";

function* getPvAnomalias() {
    try {
        const response = yield call(getPvAnomaliasApi);
        //console.log(response);
        yield put(PvAnomaliasApiResponseSuccess(GET_PV_ANOMALIAS, response.results));
    } catch (error) {
        //console.log(error);
        yield put(PvAnomaliasApiResponseError(GET_PV_ANOMALIAS, error));
    }
}

function* onAddNewPvAnomalias({ payload: pvanomalias }) {
    try {
        const response = yield call(addNewPvAnomalias, pvanomalias);
        yield put(addPvAnomaliasSuccess(response));
        toast.success("PvAnomalias Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addPvAnomaliasFail(error));
        toast.error("PvAnomalias Added Failed", { autoClose: 3000 });
    }
}

function* onDeletePvAnomalias({ payload: pvanomalias }) {
    try {
        const response = yield call(deletePvAnomalias, pvanomalias);
        yield put(deletePvAnomaliasSuccess({ pvanomalias, ...response }));
        toast.success("PvAnomalias Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deletePvAnomaliasFail(error));
        toast.error("PvAnomalias Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdatePvAnomalias({ payload: pvanomalias }) {
    try {
        const response = yield call(updatePvAnomalias, pvanomalias);
        yield put(updatePvAnomaliasSuccess(response));
        toast.success("PvAnomalias Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updatePvAnomaliasFail(error));
        toast.error("PvAnomalias Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetPvAnomalias() {
    yield takeEvery(GET_PV_ANOMALIAS, getPvAnomalias);
}

export function* watchAddNewPvAnomalias() {
    yield takeEvery(ADD_NEW_PV_ANOMALIAS, onAddNewPvAnomalias);
}

export function* watchUpdatePvAnomalias() {
    yield takeEvery(UPDATE_PV_ANOMALIAS, onUpdatePvAnomalias);
}

export function* watchDeletePvAnomalias() {
    yield takeEvery(DELETE_PV_ANOMALIAS, onDeletePvAnomalias);
}

function* pvanomaliasSaga() {
    yield all([
        fork(watchGetPvAnomalias),
        fork(watchAddNewPvAnomalias),
        fork(watchUpdatePvAnomalias),
        fork(watchDeletePvAnomalias)
    ]
    );
}

export default pvanomaliasSaga;
