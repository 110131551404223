import {
  GET_TRECHO_ANOMALIAS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_TRECHO_ANOMALIAS,
  ADD_TRECHO_ANOMALIAS_SUCCESS,
  ADD_TRECHO_ANOMALIAS_FAIL,
  UPDATE_TRECHO_ANOMALIAS,
  UPDATE_TRECHO_ANOMALIAS_SUCCESS,
  UPDATE_TRECHO_ANOMALIAS_FAIL,
  DELETE_TRECHO_ANOMALIAS,
  DELETE_TRECHO_ANOMALIAS_SUCCESS,
  DELETE_TRECHO_ANOMALIAS_FAIL,
} from "./actionType";

// common success
export const TrechoAnomaliasApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const TrechoAnomaliasApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getTrechoAnomalias = () => ({
  type: GET_TRECHO_ANOMALIAS,
});

export const updateTrechoAnomalias = trechoanomalias => ({
  type: UPDATE_TRECHO_ANOMALIAS,
  payload: trechoanomalias,
});

export const updateTrechoAnomaliasSuccess = trechoanomalias => ({
  type: UPDATE_TRECHO_ANOMALIAS_SUCCESS,
  payload: trechoanomalias,
});

export const updateTrechoAnomaliasFail = error => ({
  type: UPDATE_TRECHO_ANOMALIAS_FAIL,
  payload: error,
});

export const addNewTrechoAnomalias = trechoanomalias => ({
  type: ADD_NEW_TRECHO_ANOMALIAS,
  payload: trechoanomalias,
});

export const addTrechoAnomaliasSuccess = trechoanomalias => ({
  type: ADD_TRECHO_ANOMALIAS_SUCCESS,
  payload: trechoanomalias,
});

export const addTrechoAnomaliasFail = error => ({
  type: ADD_TRECHO_ANOMALIAS_FAIL,
  payload: error,
});
export const deleteTrechoAnomalias = trechoanomalias => ({
  type: DELETE_TRECHO_ANOMALIAS,
  payload: trechoanomalias,
});

export const deleteTrechoAnomaliasSuccess = trechoanomalias => ({
  type: DELETE_TRECHO_ANOMALIAS_SUCCESS,
  payload: trechoanomalias,
});

export const deleteTrechoAnomaliasFail = error => ({
  type: DELETE_TRECHO_ANOMALIAS_FAIL,
  payload: error,
});