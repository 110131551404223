import { combineReducers } from "redux";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Chat
import chat from "./chat/reducer";

//CiCadastros
import CiCadastros from "./cicadastros/reducer";

//CiAnomalias
import CiAnomalias from "./cianomalias/reducer";

//PvCadastros
import PvCadastros from "./pvcadastros/reducer";

//PvAnomalias
import PvAnomalias from "./pvanomalias/reducer";

//TrechoCadastros
import TrechoCadastros from "./trechocadastros/reducer";

//TrechoAnomalias
import TrechoAnomalias from "./trechoanomalias/reducer";

//Crm
import Crm from "./crm/reducer";

// Dashboard Analytics
import DashboardAnalytics from "./dashboardAnalytics/reducer";

// Dashboard CRM
import DashboardCRM from "./dashboardCRM/reducer";

// Dashboard Cryto
import DashboardProject from "./dashboardProject/reducer";

// Front
import Layout from "./layouts/reducer";

// Tasks
import Tasks from "./tasks/reducer";

// Pages > Team
import Team from "./team/reducer";

//TicketsList
import Tickets from "./tickets/reducer";

const rootReducer = combineReducers({
    // public
    Login,
    Account,
    ForgetPassword,
    Profile,
    chat,
    CiCadastros,
    CiAnomalias,
    PvCadastros,
    PvAnomalias,
    TrechoCadastros,
    TrechoAnomalias,
    Crm,
    DashboardAnalytics,
    DashboardCRM,
    DashboardProject,
    Layout,
    Tasks,
    Team,
    Tickets
});

export default rootReducer;