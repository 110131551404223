// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_TRECHO_CADASTROS = "GET_TRECHO_CADASTROS";

/**
* Add TRECHO_CADASTROS
*/
export const ADD_NEW_TRECHO_CADASTROS = "ADD_NEW_TRECHO_CADASTROS";
export const ADD_TRECHO_CADASTROS_SUCCESS = "ADD_TRECHO_CADASTROS_SUCCESS";
export const ADD_TRECHO_CADASTROS_FAIL = "ADD_TRECHO_CADASTROS_FAIL";

/**
 * Edit TRECHO_CADASTROS
 */
export const UPDATE_TRECHO_CADASTROS = "UPDATE_TRECHO_CADASTROS";
export const UPDATE_TRECHO_CADASTROS_SUCCESS = "UPDATE_TRECHO_CADASTROS_SUCCESS";
export const UPDATE_TRECHO_CADASTROS_FAIL = "UPDATE_TRECHO_CADASTROS_FAIL";

/**
 * Delete TRECHO_CADASTROS
 */
export const DELETE_TRECHO_CADASTROS = "DELETE_TRECHO_CADASTROS";
export const DELETE_TRECHO_CADASTROS_SUCCESS = "DELETE_TRECHO_CADASTROS_SUCCESS";
export const DELETE_TRECHO_CADASTROS_FAIL = "DELETE_TRECHO_CADASTROS_FAIL";