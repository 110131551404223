// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PV_ANOMALIAS = "GET_PV_ANOMALIAS";

/**
* Add PV_ANOMALIAS
*/
export const ADD_NEW_PV_ANOMALIAS = "ADD_NEW_PV_ANOMALIAS";
export const ADD_PV_ANOMALIAS_SUCCESS = "ADD_PV_ANOMALIAS_SUCCESS";
export const ADD_PV_ANOMALIAS_FAIL = "ADD_PV_ANOMALIAS_FAIL";

/**
 * Edit PV_ANOMALIAS
 */
export const UPDATE_PV_ANOMALIAS = "UPDATE_PV_ANOMALIAS";
export const UPDATE_PV_ANOMALIAS_SUCCESS = "UPDATE_PV_ANOMALIAS_SUCCESS";
export const UPDATE_PV_ANOMALIAS_FAIL = "UPDATE_PV_ANOMALIAS_FAIL";

/**
 * Delete PV_ANOMALIAS
 */
export const DELETE_PV_ANOMALIAS = "DELETE_PV_ANOMALIAS";
export const DELETE_PV_ANOMALIAS_SUCCESS = "DELETE_PV_ANOMALIAS_SUCCESS";
export const DELETE_PV_ANOMALIAS_FAIL = "DELETE_PV_ANOMALIAS_FAIL";