import {
  GET_PV_ANOMALIAS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_PV_ANOMALIAS,
  ADD_PV_ANOMALIAS_SUCCESS,
  ADD_PV_ANOMALIAS_FAIL,
  UPDATE_PV_ANOMALIAS,
  UPDATE_PV_ANOMALIAS_SUCCESS,
  UPDATE_PV_ANOMALIAS_FAIL,
  DELETE_PV_ANOMALIAS,
  DELETE_PV_ANOMALIAS_SUCCESS,
  DELETE_PV_ANOMALIAS_FAIL,
} from "./actionType";

// common success
export const PvAnomaliasApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const PvAnomaliasApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPvAnomalias = () => ({
  type: GET_PV_ANOMALIAS,
});

export const updatePvAnomalias = pvanomalias => ({
  type: UPDATE_PV_ANOMALIAS,
  payload: pvanomalias,
});

export const updatePvAnomaliasSuccess = pvanomalias => ({
  type: UPDATE_PV_ANOMALIAS_SUCCESS,
  payload: pvanomalias,
});

export const updatePvAnomaliasFail = error => ({
  type: UPDATE_PV_ANOMALIAS_FAIL,
  payload: error,
});

export const addNewPvAnomalias = pvanomalias => ({
  type: ADD_NEW_PV_ANOMALIAS,
  payload: pvanomalias,
});

export const addPvAnomaliasSuccess = pvanomalias => ({
  type: ADD_PV_ANOMALIAS_SUCCESS,
  payload: pvanomalias,
});

export const addPvAnomaliasFail = error => ({
  type: ADD_PV_ANOMALIAS_FAIL,
  payload: error,
});
export const deletePvAnomalias = pvanomalias => ({
  type: DELETE_PV_ANOMALIAS,
  payload: pvanomalias,
});

export const deletePvAnomaliasSuccess = pvanomalias => ({
  type: DELETE_PV_ANOMALIAS_SUCCESS,
  payload: pvanomalias,
});

export const deletePvAnomaliasFail = error => ({
  type: DELETE_PV_ANOMALIAS_FAIL,
  payload: error,
});