import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

//Import Breadcrumb
import BreadCrumb from '../../../Components/Common/BreadCrumb';

//Import maps
import Esgoto from "./MapsWithData/Esgoto";

const LeafletMaps = () => {
    document.title = "WebMap Esgoto | SANapp";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Esgoto" pageTitle="WebMaps" />

                    <Row >
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="leaflet-map-group-control" className="leaflet-map">
                                        <Esgoto />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default LeafletMaps;