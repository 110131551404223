import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, Col, Row, Label, Table } from 'reactstrap';

import proj4 from 'proj4';
// Api Conection
import { useSelector, useDispatch } from "react-redux";
import { getCiCadastrosfilter } from "../../../store/cicadastros/action";


const SearchOption = () => {
    const [value, setValue] = useState("");
    const onChangeData = (value) => {
        setValue(value);
    };

    useEffect(() => {
        /*
        var searchOptions = document.getElementById("search-close-options");
        var dropdown = document.getElementById("search-dropdown");
        var searchInput = document.getElementById("search-options");

        searchInput.addEventListener("focus", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchInput.addEventListener("keyup", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchOptions.addEventListener("click", function () {
            searchInput.value = "";
            dropdown.classList.remove("show");
            searchOptions.classList.add("d-none");
        });

        document.body.addEventListener("click", function (e) {
            if (e.target.getAttribute('id') !== "search-options") {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });
        */

    }, []);

    const [searchOptions, setSearchOptions] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    // How do search system?




    // CARREGA DADOS API COM REDUX
    const dispatch = useDispatch();

    // GET CI CADASTROS
    const { ciCadastrosFilter, isCiCadastrosCreated, isCiCadastrosSuccess, errorCiCadastros } = useSelector((state) => ({
        ciCadastrosFilter: state.CiCadastros.ciCadastrosFilter,
        isCiCadastrosCreated: state.CiCadastros.isCiCadastrosCreated,
        isCiCadastrosSuccess: state.CiCadastros.isCiCadastrosSuccess,
        error: state.CiCadastros.error,
    }));
    useEffect(() => {
        if (ciCadastrosFilter && !ciCadastrosFilter.length) {
            //dispatch(getCiCadastrosfilter({fid:10}));
            dispatch(getCiCadastrosfilter());
        }
    }, [dispatch, ciCadastrosFilter]);
    // console.log(ciCadastrosFilter);

    // CONVERTE AS COORDENADAS
    const transformCoord = (coord) => {
        proj4.defs("EPSG:31982", "+proj=utm +zone=22 +south +ellps=GRS80 +units=m +no_defs");
        const coordArray = coord.split('(')[1].split(')')[0].split(' ');
        const x = parseFloat(coordArray[0]);
        const y = parseFloat(coordArray[1]);
        const transformed = proj4("EPSG:31982", "EPSG:4326", [x, y]);
        return [transformed[1], transformed[0]]
    };
    const transformline = (line) => {
        proj4.defs("EPSG:31982", "+proj=utm +zone=22 +south +ellps=GRS80 +units=m +no_defs");
        const lineArray = line.replace(',', '').replace('((', '(').replace('))', ')').split('(')[1].split(')')[0].split(' ');
        const w = parseFloat(lineArray[0]);
        const x = parseFloat(lineArray[1]);
        const y = parseFloat(lineArray[2]);
        const z = parseFloat(lineArray[3]);
        const transformed1 = proj4("EPSG:31982", "EPSG:4326", [w, x]);
        const transformed2 = proj4("EPSG:31982", "EPSG:4326", [y, z]);
        return [[transformed1[1], transformed1[0]], [transformed2[1], transformed2[0]]]
    };

    return (
        <React.Fragment>

            <div className="content">

                <form className="app-search d-none d-md-block">

                    <Col lg={4} md={3} className='top-50'>
                        <div className="mt-3 mt-lg-0">
                            <p className="text-muted fw-medium">Selecione a categoria do ativo</p>
                            <div className="form-check form-check-inline mb-2">
                                <Input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked />
                                <Label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Esgoto
                                </Label>
                            </div>
                            <div className="form-check form-check-inline">
                                <Input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                <Label className="form-check-label" htmlFor="flexRadioDefault2">
                                    Drenagem
                                </Label>
                            </div>
                            <div className="form-check form-check-inline">
                                <Input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                <Label className="form-check-label" htmlFor="flexRadioDefault3">
                                    Agua
                                </Label>
                            </div>
                        </div>
                    </Col>

                    <br />

                    <Row className="g-3">
                        <p className="text-muted fw-medium">Selecione abaixo os parametros</p>
                    </Row>

                    <Row className="g-3">
                        <Col lg={4}>
                            <div className="input-group">
                                <label className="input-group-text" for="inputGroupSelect02">01</label>
                                <select className="form-select" id="inputGroupSelect02">
                                    <option selected>adicionar...</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="input-group">
                                <label className="input-group-text" for="inputGroupSelect02">02</label>
                                <select className="form-select" id="inputGroupSelect02">
                                    <option selected>adicionar...</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="input-group">
                                <label className="input-group-text" for="inputGroupSelect02">03</label>
                                <select className="form-select" id="inputGroupSelect02">
                                    <option selected>adicionar...</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </Col>
                    </Row>


                    <div className="text-center pt-3 pb-1">
                        <Link to="/pages-search-results" className="btn btn-primary btn-sm"> Buscar <i
                            className="ri-arrow-right-line ms-1"></i></Link>
                    </div>

                </form>

                <Row>
                    <Col xl={12}>
                        <div className="table-responsive">
                            <Table className="table-hover align-middle table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ "width": "25px" }}>
                                            <div className="form-check">
                                                <Input className="form-check-input" type="checkbox" id="checkAll" defaultValue="option1" />
                                            </div>
                                        </th>
                                        <th scope="col">Id</th>
                                        <th scope="col">Coordenadas</th>
                                        <th scope="col">fid</th>
                                        <th scope="col">Id caixa inspeção</th>
                                        <th scope="col">n_edificac</th>
                                        <th scope="col">tipo caixa inspeção</th>
                                        <th scope="col">local caixa inspeção</th>
                                        <th scope="col">material tampa</th>
                                        <th scope="col">diametro tampa</th>
                                        <th scope="col">pav_calcad</th>
                                        <th scope="col">prof_poco</th>
                                        <th scope="col">mat_poco</th>
                                        <th scope="col">form_poco</th>
                                        <th scope="col">dim_int_po</th>
                                        <th scope="col">dim_poco_r</th>
                                        <th scope="col">mat_tubo_s</th>
                                        <th scope="col">dim_tubo_s</th>
                                        <th scope="col">data_dado</th>
                                        <th scope="col">font_comp</th>
                                        <th scope="col">font_geom</th>
                                        <th scope="col">orig_arq</th>
                                        <th scope="col">cidade</th>
                                        <th scope="col">bairro</th>
                                        <th scope="col">logradouro</th>
                                        <th scope="col">mes</th>
                                        <th scope="col">ano</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {ciCadastrosFilter.map((item) => (

                                        <tr>
                                            <th scope="row">
                                                <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`} value="option1" />
                                                </div>
                                            </th>
                                            <td>#{item.id}</td>
                                            <td>{transformCoord(item.geom)}</td>
                                            <th scope="col">{item.fid}</th>
                                            <th scope="col">{item.id_ci}</th>
                                            <th scope="col">{item.n_edificac}</th>
                                            <th scope="col">{item.tipo_ci}</th>
                                            <th scope="col">{item.local_ci}</th>
                                            <th scope="col">{item.mat_tampa}</th>
                                            <th scope="col">{item.diam_tampa}</th>
                                            <th scope="col">{item.pav_calcad}</th>
                                            <th scope="col">{item.prof_poco}</th>
                                            <th scope="col">{item.mat_poco}</th>
                                            <th scope="col">{item.form_poco}</th>
                                            <th scope="col">{item.dim_int_po}</th>
                                            <th scope="col">{item.dim_poco_r}</th>
                                            <th scope="col">{item.mat_tubo_s}</th>
                                            <th scope="col">{item.dim_tubo_s}</th>
                                            <th scope="col">{item.data_dado}</th>
                                            <th scope="col">{item.font_comp}</th>
                                            <th scope="col">{item.font_geom}</th>
                                            <th scope="col">{item.orig_arq}</th>
                                            <th scope="col">{item.cidade}</th>
                                            <th scope="col">{item.bairro}</th>
                                            <th scope="col">{item.logradouro}</th>
                                            <th scope="col">{item.mes}</th>
                                            <th scope="col">{item.ano}</th>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table>
                        </div>
                    </Col>

                </Row>

            </div>

        </React.Fragment>
    );
};

export default SearchOption;