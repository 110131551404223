// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_TRECHO_ANOMALIAS = "GET_TRECHO_ANOMALIAS";

/**
* Add TRECHO_ANOMALIAS
*/
export const ADD_NEW_TRECHO_ANOMALIAS = "ADD_NEW_TRECHO_ANOMALIAS";
export const ADD_TRECHO_ANOMALIAS_SUCCESS = "ADD_TRECHO_ANOMALIAS_SUCCESS";
export const ADD_TRECHO_ANOMALIAS_FAIL = "ADD_TRECHO_ANOMALIAS_FAIL";

/**
 * Edit TRECHO_ANOMALIAS
 */
export const UPDATE_TRECHO_ANOMALIAS = "UPDATE_TRECHO_ANOMALIAS";
export const UPDATE_TRECHO_ANOMALIAS_SUCCESS = "UPDATE_TRECHO_ANOMALIAS_SUCCESS";
export const UPDATE_TRECHO_ANOMALIAS_FAIL = "UPDATE_TRECHO_ANOMALIAS_FAIL";

/**
 * Delete TRECHO_ANOMALIAS
 */
export const DELETE_TRECHO_ANOMALIAS = "DELETE_TRECHO_ANOMALIAS";
export const DELETE_TRECHO_ANOMALIAS_SUCCESS = "DELETE_TRECHO_ANOMALIAS_SUCCESS";
export const DELETE_TRECHO_ANOMALIAS_FAIL = "DELETE_TRECHO_ANOMALIAS_FAIL";