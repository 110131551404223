import {
  GET_CI_ANOMALIAS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_CI_ANOMALIAS,
  ADD_CI_ANOMALIAS_SUCCESS,
  ADD_CI_ANOMALIAS_FAIL,
  UPDATE_CI_ANOMALIAS,
  UPDATE_CI_ANOMALIAS_SUCCESS,
  UPDATE_CI_ANOMALIAS_FAIL,
  DELETE_CI_ANOMALIAS,
  DELETE_CI_ANOMALIAS_SUCCESS,
  DELETE_CI_ANOMALIAS_FAIL,
} from "./actionType";

// common success
export const CiAnomaliasApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const CiAnomaliasApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCiAnomalias = (cianomalias) => ({
  type: GET_CI_ANOMALIAS,
  payload: cianomalias,
});

export const updateCiAnomalias = cianomalias => ({
  type: UPDATE_CI_ANOMALIAS,
  payload: cianomalias,
});

export const updateCiAnomaliasSuccess = cianomalias => ({
  type: UPDATE_CI_ANOMALIAS_SUCCESS,
  payload: cianomalias,
});

export const updateCiAnomaliasFail = error => ({
  type: UPDATE_CI_ANOMALIAS_FAIL,
  payload: error,
});

export const addNewCiAnomalias = cianomalias => ({
  type: ADD_NEW_CI_ANOMALIAS,
  payload: cianomalias,
});

export const addCiAnomaliasSuccess = cianomalias => ({
  type: ADD_CI_ANOMALIAS_SUCCESS,
  payload: cianomalias,
});

export const addCiAnomaliasFail = error => ({
  type: ADD_CI_ANOMALIAS_FAIL,
  payload: error,
});
export const deleteCiAnomalias = cianomalias => ({
  type: DELETE_CI_ANOMALIAS,
  payload: cianomalias,
});

export const deleteCiAnomaliasSuccess = cianomalias => ({
  type: DELETE_CI_ANOMALIAS_SUCCESS,
  payload: cianomalias,
});

export const deleteCiAnomaliasFail = error => ({
  type: DELETE_CI_ANOMALIAS_FAIL,
  payload: error,
});