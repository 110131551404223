import {
    GET_TRECHO_CADASTROS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_TRECHO_CADASTROS_SUCCESS,
    ADD_TRECHO_CADASTROS_FAIL,
    UPDATE_TRECHO_CADASTROS_SUCCESS,
    UPDATE_TRECHO_CADASTROS_FAIL,
    DELETE_TRECHO_CADASTROS_SUCCESS,
    DELETE_TRECHO_CADASTROS_FAIL,
} from "./actionType";

const INIT_STATE = {
    trechoCadastros: [],
};

const TrechoCadastros = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_TRECHO_CADASTROS:
                    return {
                        ...state,
                        trechoCadastros: action.payload.data,
                        isTrechoCadastrosCreated: false,
                        isTrechoCadastrosSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_TRECHO_CADASTROS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isTrechoCadastrosCreated: false,
                        isTrechoCadastrosSuccess: true
                    };

                default:
                    return { ...state };
            }

        case GET_TRECHO_CADASTROS: {
            return {
                ...state,
                isTrechoCadastrosCreated: false
            };
        }

        case ADD_TRECHO_CADASTROS_SUCCESS:
            return {
                ...state,
                isTrechoCadastrosCreated: true,
                trechoCadastros: [...state.trechoCadastros, action.payload.data],
                isTrechoCadastrosAdd: true,
                isTrechoCadastrosAddFail: false,
            };

        case ADD_TRECHO_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isTrechoCadastrosAdd: false,
                isTrechoCadastrosAddFail: true,
            };

        case UPDATE_TRECHO_CADASTROS_SUCCESS:
            return {
                ...state,
                trechoCadastros: state.trechoCadastros.map(trechocadastros =>
                    trechocadastros._id.toString() === action.payload.data._id.toString()
                        ? { ...trechocadastros, ...action.payload.data }
                        : trechocadastros
                ),
                isTrechoCadastrosUpdate: true,
                isTrechoCadastrosUpdateFail: false
            };

        case UPDATE_TRECHO_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isTrechoCadastrosUpdate: false,
                isTrechoCadastrosUpdateFail: true
            };

        case DELETE_TRECHO_CADASTROS_SUCCESS:
            return {
                ...state,
                trechoCadastros: state.trechoCadastros.filter(
                    trechocadastros => trechocadastros._id.toString() !== action.payload.trechocadastros.toString()
                ),
                isTrechoCadastrosDelete: true,
                isTrechoCadastrosDeleteFail: false
            };

        case DELETE_TRECHO_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isTrechoCadastrosDelete: false,
                isTrechoCadastrosDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default TrechoCadastros;