// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_CI_ANOMALIAS = "GET_CI_ANOMALIAS";

/**
* Add CI_ANOMALIAS
*/
export const ADD_NEW_CI_ANOMALIAS = "ADD_NEW_CI_ANOMALIAS";
export const ADD_CI_ANOMALIAS_SUCCESS = "ADD_CI_ANOMALIAS_SUCCESS";
export const ADD_CI_ANOMALIAS_FAIL = "ADD_CI_ANOMALIAS_FAIL";

/**
 * Edit CI_ANOMALIAS
 */
export const UPDATE_CI_ANOMALIAS = "UPDATE_CI_ANOMALIAS";
export const UPDATE_CI_ANOMALIAS_SUCCESS = "UPDATE_CI_ANOMALIAS_SUCCESS";
export const UPDATE_CI_ANOMALIAS_FAIL = "UPDATE_CI_ANOMALIAS_FAIL";

/**
 * Delete CI_ANOMALIAS
 */
export const DELETE_CI_ANOMALIAS = "DELETE_CI_ANOMALIAS";
export const DELETE_CI_ANOMALIAS_SUCCESS = "DELETE_CI_ANOMALIAS_SUCCESS";
export const DELETE_CI_ANOMALIAS_FAIL = "DELETE_CI_ANOMALIAS_FAIL";