import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";

//Dashboard
//import DashboardAnalytics from "../pages/DashboardAnalytics";
//import DashboardCrm from "../pages/DashboardCrm";

//Maps
//import LeafletMaps from "../pages/Maps/LeafletMaps/LeafletMaps";
import MapEsgoto from "../pages/Maps/LeafletMaps/MapEsgoto";
import FilterSearch from "../pages/Search/FilterSearch";

//Services
import Orders from "../pages/Services/Orders";

//pages
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
//import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
//import Error500 from '../pages/AuthenticationInner/Errors/Error500';

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
//import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";

// User Profile
//import UserProfile from "../pages/Authentication/user-profile";
const RedirectToWebmaps = () => {
    const history = useHistory();
  
    useEffect(() => {
      history.replace("/webmaps");
    }, [history]);
  
    return null;
  };

const authProtectedRoutes = [
    //{ path: "/dashboard-analytics", component: DashboardAnalytics },
    //{ path: "/dashboard-crm", component: DashboardCrm },

    //Maps
    { path: "/webmaps", component: MapEsgoto },
    //{ path: "/leaflet-webmaps", component: LeafletMaps },
    
    //Search
    { path: "/filter-search", component: FilterSearch },
    
    //Order Service
    { path: "/ordem-servico", component: Orders },

    //User Profile
    //{ path: "/profile", component: UserProfile },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    //{path: "/", exact: true, component: () => <Redirect to="/webmaps" />,},
    //{ path: "/", exact: true, component: () => { history.replace("/webmaps"); return null; } },
    {path: "/", exact: true, component: RedirectToWebmaps},

];


const publicRoutes = [
    // Authentication Page
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    //{ path: "/forgot-password", component: ForgetPasswordPage },

    //AuthenticationInner pages
    { path: "/auth-404-basic", component: Basic404 },
    //{ path: "/auth-404-cover", component: Cover404 },
    //{ path: "/auth-500", component: Error500 },

];

export { authProtectedRoutes, publicRoutes };