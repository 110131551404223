import {
  GET_CI_CADASTROS,
  GET_CI_CADASTROSFILTER,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_CI_CADASTROS,
  ADD_CI_CADASTROS_SUCCESS,
  ADD_CI_CADASTROS_FAIL,
  UPDATE_CI_CADASTROS,
  UPDATE_CI_CADASTROS_SUCCESS,
  UPDATE_CI_CADASTROS_FAIL,
  DELETE_CI_CADASTROS,
  DELETE_CI_CADASTROS_SUCCESS,
  DELETE_CI_CADASTROS_FAIL,
} from "./actionType";

// common success
export const CiCadastrosApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const CiCadastrosApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCiCadastros = cicadastros => ({
  type: GET_CI_CADASTROS,
  payload: cicadastros,
});

// common success
export const CiCadastrosFilterResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const CiCadastrosFilterResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCiCadastrosfilter = cicadastros => ({
  type: GET_CI_CADASTROSFILTER,
  payload: cicadastros,
});

export const updateCiCadastros = cicadastros => ({
  type: UPDATE_CI_CADASTROS,
  payload: cicadastros,
});

export const updateCiCadastrosSuccess = cicadastros => ({
  type: UPDATE_CI_CADASTROS_SUCCESS,
  payload: cicadastros,
});

export const updateCiCadastrosFail = error => ({
  type: UPDATE_CI_CADASTROS_FAIL,
  payload: error,
});

export const addNewCiCadastros = cicadastros => ({
  type: ADD_NEW_CI_CADASTROS,
  payload: cicadastros,
});

export const addCiCadastrosSuccess = cicadastros => ({
  type: ADD_CI_CADASTROS_SUCCESS,
  payload: cicadastros,
});

export const addCiCadastrosFail = error => ({
  type: ADD_CI_CADASTROS_FAIL,
  payload: error,
});
export const deleteCiCadastros = cicadastros => ({
  type: DELETE_CI_CADASTROS,
  payload: cicadastros,
});

export const deleteCiCadastrosSuccess = cicadastros => ({
  type: DELETE_CI_CADASTROS_SUCCESS,
  payload: cicadastros,
});

export const deleteCiCadastrosFail = error => ({
  type: DELETE_CI_CADASTROS_FAIL,
  payload: error,
});