import { all, fork } from "redux-saga/effects";

//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

//chat
import chatSaga from "./chat/saga";

//cicadastros
import cicadastrosSaga from "./cicadastros/saga";

//cianomalias
import cianomaliasSaga from "./cianomalias/saga";

//pvcadastros
import pvcadastrosSaga from "./pvcadastros/saga";

//pvanomalias
import pvanomaliasSaga from "./pvanomalias/saga";

//trechocadastros
import trechocadastrosSaga from "./trechocadastros/saga";

//trechoanomalias
import trechoanomaliasSaga from "./trechoanomalias/saga";

//crm
import crmSaga from "./crm/saga";

// Dashboard Analytics
import dashboardAnalyticsSaga from "./dashboardAnalytics/saga";

// Dashboard CRM
import dashboardCrmSaga from "./dashboardCRM/saga";

// Dashboard Project
import dashboardProjectSaga from "./dashboardProject/saga";

//layout
import LayoutSaga from "./layouts/saga";

// Task
import taskSaga from "./tasks/saga";

// Pages > Team
import teamSaga from "./team/saga";

//TicketsList
import ticketsSaga from "./tickets/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(chatSaga),
    fork(cicadastrosSaga),
    fork(cianomaliasSaga),    
    fork(pvcadastrosSaga),
    fork(pvanomaliasSaga),
    fork(trechocadastrosSaga),
    fork(trechoanomaliasSaga),
    fork(crmSaga),
    fork(dashboardAnalyticsSaga),
    fork(dashboardCrmSaga),
    fork(dashboardProjectSaga),
    fork(LayoutSaga),
    fork(taskSaga),
    fork(teamSaga),
    fork(ticketsSaga),
  ]);
}
