import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

//Import maps
import FilterSearchForm from "./FilterSearch/FilterSearchForm";

const FilterSearch = () => {
    document.title = "Filtros e Buscas | SANapp";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Filter and search" pageTitle="Search" />

                    <Row >
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="filter-search" className="filter-search">
                                        <FilterSearchForm />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FilterSearch;