// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_CI_CADASTROS = "GET_CI_CADASTROS";
export const GET_CI_CADASTROSFILTER = "GET_CI_CADASTROSFILTER";

/**
* Add CI_CADASTROS
*/
export const ADD_NEW_CI_CADASTROS = "ADD_NEW_CI_CADASTROS";
export const ADD_CI_CADASTROS_SUCCESS = "ADD_CI_CADASTROS_SUCCESS";
export const ADD_CI_CADASTROS_FAIL = "ADD_CI_CADASTROS_FAIL";

/**
 * Edit CI_CADASTROS
 */
export const UPDATE_CI_CADASTROS = "UPDATE_CI_CADASTROS";
export const UPDATE_CI_CADASTROS_SUCCESS = "UPDATE_CI_CADASTROS_SUCCESS";
export const UPDATE_CI_CADASTROS_FAIL = "UPDATE_CI_CADASTROS_FAIL";

/**
 * Delete CI_CADASTROS
 */
export const DELETE_CI_CADASTROS = "DELETE_CI_CADASTROS";
export const DELETE_CI_CADASTROS_SUCCESS = "DELETE_CI_CADASTROS_SUCCESS";
export const DELETE_CI_CADASTROS_FAIL = "DELETE_CI_CADASTROS_FAIL";