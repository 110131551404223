import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// TRECHO_ANOMALIAS Redux States
import {
    GET_TRECHO_ANOMALIAS,
    ADD_NEW_TRECHO_ANOMALIAS,
    DELETE_TRECHO_ANOMALIAS,
    UPDATE_TRECHO_ANOMALIAS,
} from "./actionType";
import {
    TrechoAnomaliasApiResponseSuccess, TrechoAnomaliasApiResponseError,
    addTrechoAnomaliasSuccess,
    addTrechoAnomaliasFail,
    updateTrechoAnomaliasSuccess,
    updateTrechoAnomaliasFail,
    deleteTrechoAnomaliasSuccess,
    deleteTrechoAnomaliasFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getTrechoAnomalias as getTrechoAnomaliasApi,
    addNewTrechoAnomalias,
    updateTrechoAnomalias,
    deleteTrechoAnomalias,
} from "../../helpers/fakebackend_helper";

function* getTrechoAnomalias() {
    try {
        const response = yield call(getTrechoAnomaliasApi);
        //console.log(response);
        yield put(TrechoAnomaliasApiResponseSuccess(GET_TRECHO_ANOMALIAS, response.results));
    } catch (error) {
        //console.log(error);
        yield put(TrechoAnomaliasApiResponseError(GET_TRECHO_ANOMALIAS, error));
    }
}

function* onAddNewTrechoAnomalias({ payload: trechoanomalias }) {
    try {
        const response = yield call(addNewTrechoAnomalias, trechoanomalias);
        yield put(addTrechoAnomaliasSuccess(response));
        toast.success("TrechoAnomalias Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addTrechoAnomaliasFail(error));
        toast.error("TrechoAnomalias Added Failed", { autoClose: 3000 });
    }
}

function* onDeleteTrechoAnomalias({ payload: trechoanomalias }) {
    try {
        const response = yield call(deleteTrechoAnomalias, trechoanomalias);
        yield put(deleteTrechoAnomaliasSuccess({ trechoanomalias, ...response }));
        toast.success("TrechoAnomalias Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteTrechoAnomaliasFail(error));
        toast.error("TrechoAnomalias Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdateTrechoAnomalias({ payload: trechoanomalias }) {
    try {
        const response = yield call(updateTrechoAnomalias, trechoanomalias);
        yield put(updateTrechoAnomaliasSuccess(response));
        toast.success("TrechoAnomalias Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateTrechoAnomaliasFail(error));
        toast.error("TrechoAnomalias Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetTrechoAnomalias() {
    yield takeEvery(GET_TRECHO_ANOMALIAS, getTrechoAnomalias);
}

export function* watchAddNewTrechoAnomalias() {
    yield takeEvery(ADD_NEW_TRECHO_ANOMALIAS, onAddNewTrechoAnomalias);
}

export function* watchUpdateTrechoAnomalias() {
    yield takeEvery(UPDATE_TRECHO_ANOMALIAS, onUpdateTrechoAnomalias);
}

export function* watchDeleteTrechoAnomalias() {
    yield takeEvery(DELETE_TRECHO_ANOMALIAS, onDeleteTrechoAnomalias);
}

function* trechoanomaliasSaga() {
    yield all([
        fork(watchGetTrechoAnomalias),
        fork(watchAddNewTrechoAnomalias),
        fork(watchUpdateTrechoAnomalias),
        fork(watchDeleteTrechoAnomalias)
    ]
    );
}

export default trechoanomaliasSaga;
